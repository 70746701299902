import React, { useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { OrganizationSelect, UserViewInformation } from './UserInfoPage'
import { OrganizationAccessRequest } from '../../types/apiTypes'
import { useIntl } from 'react-intl'

import './UserOrganizations.scss'
import { useClient } from '../../api/clientContext'
import { useDispatch } from 'react-redux'
import { setFlashMsg } from '../../store/app/index'

type PendingRequest = {
  id: string
  organizationName: string
  email: string
  status: number
}

const PendingRequests: React.FC<{
  reqs: PendingRequest[]
  setRefreshing: (b: boolean) => void
}> = ({ reqs, setRefreshing }) => {
  const intl = useIntl()
  const client = useClient()
  // TODO: Should you use redux editor/setLoading instead?
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const mapReqStatusToTranslation = (status: number): string => {
    if (status === 2) return intl.formatMessage({ id: 'request-rejected' })
    if (status === 3) return intl.formatMessage({ id: 'request-granted' })

    return intl.formatMessage({ id: 'request-pending' })
  }

  const handleCancelAccessRequest = async (id: string): Promise<void> => {
    if (!client) {
      console.warn('Client not initialized')
      return
    }
    setLoading(true)

    try {
      await client.delete(`organizationaccessrequest/${id}`)
      dispatch(
        setFlashMsg({
          msg: 'organization-access-request-cancelled',
          style: 'message',
          sticky: false,
        })
      )
    } catch (error) {
      dispatch(
        setFlashMsg({
          msg: 'generic-error-message',
          style: 'error',
          sticky: false,
        })
      )
    }
    setLoading(false)
    setRefreshing(true)
  }

  return (
    <table>
      <thead>
        <tr>
          <th>{intl.formatMessage({ id: 'name' })}</th>
          <th>{intl.formatMessage({ id: 'email' })}</th>
          <th>{intl.formatMessage({ id: 'status' })}</th>
        </tr>
      </thead>
      <tbody>
        {reqs.map((req) => (
          <tr key={req.id}>
            <td>{req.organizationName}</td>
            <td>{req.email}</td>
            <td>
              <strong>{mapReqStatusToTranslation(req.status)}</strong>
            </td>
            <td>
              <Button
                className="cancel-oar"
                disabled={loading || req.status !== 1}
                variant="outlined"
                onClick={() => handleCancelAccessRequest(req.id)}
                endIcon={
                  loading && <CircularProgress color="inherit" size={25} />
                }
              >
                {intl.formatMessage({ id: 'cancel-request' })}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const UserOrganizations: React.FC<{
  loading: boolean
  userViewInfo?: UserViewInformation
  organizationAccessRequests: OrganizationAccessRequest[]
  organizations: OrganizationSelect[]
  setRefreshing: (b: boolean) => void
}> = ({
  loading,
  userViewInfo,
  organizationAccessRequests,
  organizations,
  setRefreshing,
}) => {
  const intl = useIntl()

  if (loading) return <CircularProgress />

  if (!userViewInfo?.adminOrganizations && !organizationAccessRequests.length) {
    return (
      <p>
        <i>{intl.formatMessage({ id: 'no-organizations' })}</i>
      </p>
    )
  }

  const pendingRequests: PendingRequest[] = organizationAccessRequests
    .filter((request) => request.request_status === 1)
    .map((request) => ({
      id: request.id,
      organizationName:
        organizations.find((org) => org.id === request.organization)?.label ||
        '',
      email: request.email,
      status: request.request_status,
    }))

  return (
    <>
      <div className="organization-container">
        {userViewInfo?.adminOrganizations.length !== 0 ? (
          <>
            <p>{intl.formatMessage({ id: 'organization-access-rights' })}</p>
            <table>
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'name' })}</th>
                  <th>{intl.formatMessage({ id: 'email' })}</th>
                </tr>
              </thead>
              <tbody>
                {userViewInfo?.adminOrganizations.map((orgId) => (
                  <tr key={orgId}>
                    <td>
                      {organizations.find((org) => org.id === orgId)?.label}
                    </td>
                    <td>
                      {
                        organizationAccessRequests.find(
                          (oar) => oar.organization === orgId
                        )?.email
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{intl.formatMessage({ id: 'no-organizations' })}</p>
        )}
      </div>
      <div className="pending-oar-container">
        {!!pendingRequests.length && (
          <>
            <p>{intl.formatMessage({ id: 'pending-access-requests' })}</p>
            <PendingRequests
              reqs={pendingRequests}
              setRefreshing={setRefreshing}
            />
          </>
        )}
      </div>
    </>
  )
}

export default UserOrganizations
