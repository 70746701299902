import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { update as oldEditor } from '../../reducers/editor'
import { Language } from '../../types/apiTypes'
import {
  EditorMode,
  EditorState,
  EditorValues,
  ValidationErrors,
} from './types'

const initialState: EditorState = {
  values: {
    description_html: {
      fi: null,
      sv: null,
      en: null,
    },
    keywords: [],
    hobbyCategories: [],
    libraryKeywords: [],
    in_language: [],
    sub_events: {},
  },
  languages: {},
  contentLanguages: ['fi'],
  keywordSets: [],
  validationErrors: {},
  validateFor: null,
  loading: false,
  serverErrorData: {},
}

// utils
/**
 * Deletes a single event from values - object.
 * @param values - A reference to the values object that is modified.
 * @param id - key of the sub event.
 */
const deleteSingleSubEvent = (
  values: EditorValues,
  event: EditorValues,
  id: number
) => {
  if (!values.sub_events?.[id]) {
    return
  }

  if (event?.['@id']) {
    // sub event is already saved in the backend.
    // mark it as deleted.
    values.sub_events[id].markAsDeleted = true
  } else {
    // sub event is not saved in the backend.
    // just remove it from the editor.
    delete values.sub_events[id]
  }
}

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    editorModeSet(
      state: EditorState,
      action: PayloadAction<{ mode: EditorMode | undefined }>
    ) {
      const { mode } = action.payload
      state.mode = mode
    },

    setHtmlDescription(
      state: EditorState,
      action: PayloadAction<{ lang: Language; text: string }>
    ) {
      const { lang, text } = action.payload
      state.values.description_html = {
        ...state.values.description_html,
        [lang]: text,
      }
    },

    /**
     * Delete sub event from redux store,
     * or mark it as deleted if it's already saved in the backend.
     */
    deleteSubEvent(
      state: EditorState,
      action: PayloadAction<{
        event: EditorValues
        subEventKey: number
      }>
    ) {
      deleteSingleSubEvent(
        state.values,
        action.payload.event,
        action.payload.subEventKey
      )
    },

    deleteAllSubEvents(
      state: EditorState,
      action: PayloadAction<EditorValues>
    ) {
      if (!action.payload || !action.payload.sub_events) {
        return
      }

      for (const key of Object.keys(action.payload.sub_events)) {
        deleteSingleSubEvent(
          state.values,
          action.payload.sub_events[+key],
          +key
        )
      }
    },

    setLoading(state: EditorState, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

// Disabling these warnings is okay while refactoring from old editor reducer to the new one
// Once it's done, these warnings should be taken care of if still present
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const combinedEditor = (state: EditorState, action: any): EditorState => {
  const intermediateState = oldEditor(state, action)
  const reducer = editorSlice.reducer(intermediateState, action)
  return reducer
}

// exports

export const {
  editorModeSet,
  setHtmlDescription,
  deleteSubEvent,
  deleteAllSubEvents,
  setLoading,
} = editorSlice.actions

export { EditorMode, EditorValues, ValidationErrors, EditorState }

export default combinedEditor
