import { get, keys, map, sortBy } from 'lodash'

import { constants } from '../constants'
import { mapAPIDataToUIFormat } from '../utils/formDataMapping'
import { getContentLanguages } from '../utils/language'
import { doValidations } from '../validation/validator'
import moment from 'moment'

let editorValues = {
  sub_events: {},
  hobbyCategories: [],
}
let localStorageEditorValues
const editorValuesKey = constants.EDITOR_VALUES

let languages = {}

/** @type {import('../types/apiTypes').KeywordSet[]} */
let keywordSets = []

/** @param {string} key */
function getItem(key) {
  const itemAsString = localStorage.getItem(key)
  if (itemAsString) {
    return JSON.parse(itemAsString)
  }

  return ''
}

localStorageEditorValues = getItem(editorValuesKey)
keywordSets = getItem('KEYWORDSETS')
languages = getItem('LANGUAGES')

/** @type {import('../store/editor').EditorState} state */
const initialState = {
  values: localStorageEditorValues || editorValues || {},
  languages,
  contentLanguages: ['fi'],
  keywordSets,
  validationErrors: {},
  validateFor: null,
  loading: false,
  serverErrorData: {},
  mode: undefined,
}

function clearEventDataFromLocalStorage() {
  localStorage.setItem(editorValuesKey, JSON.stringify({}))
}

/**
 * @param {import('../store/editor').EditorState} state
 * @param {any} action
 * @returns {import('../store/editor').EditorState}
 */
export const update = (state = initialState, action) => {
  if (action.type === constants.EDITOR_SETDATA) {
    let newValues = {}
    // Merge new values to existing values
    if (action.event) {
      newValues = {
        ...state.values,
        sub_events: {
          ...state.values.sub_events,
          [action.key]: action.values[action.key],
        },
      }
    } else if (action.offer) {
      newValues = {
        ...state.values,
        offers: Object.values({
          ...state.values.offers,
          [action.key]: action.values[action.key],
        }),
      }
    } else {
      newValues = {
        ...state.values,
        ...action.values,
      }
    }

    if (state.mode === 'create')
      localStorage.setItem(editorValuesKey, JSON.stringify(newValues))

    let validationErrors = {
      ...state.validationErrors,
    }
    // If there are validation errors, check if they are fixed
    if (keys(state.validationErrors).length > 0) {
      validationErrors = doValidations(
        { ...newValues, serverErrorData: state.serverErrorData },
        state.contentLanguages,
        state.validateFor || constants.PUBLICATION_STATUS.PUBLIC,
        state.keywordSets
      )
    }

    const payload = {
      ...state,
      values: newValues,
      validationErrors,
    }

    return payload
  }

  if (action.type === constants.EDITOR_UPDATE_SUB_EVENT) {
    const newValues = {
      ...state.values,
      sub_events: {
        ...state.values.sub_events,
        [action.eventKey]: {
          ...state.values.sub_events[action.eventKey],
          [action.property]: action.value,
        },
      },
    }

    let validationErrors = {
      ...state.validationErrors,
    }
    // If there are validation errors in sub_events, check if they are fixed
    if (state.validationErrors.sub_events) {
      validationErrors = doValidations(
        newValues,
        state.contentLanguages,
        state.validateFor || constants.PUBLICATION_STATUS.PUBLIC,
        state.keywordSets
      )
    }

    const x = {
      ...state,
      values: newValues,
      validationErrors,
    }
    return x
  }

  if (action.type === constants.EDITOR_SORT_SUB_EVENTS) {
    const mappedSubEvents = map(state.values.sub_events).filter((event) =>
      // eslint-disable-next-line no-extra-boolean-cast
      !!event.end_time
        ? moment(event.end_time).isAfter(moment())
        : moment(event.start_time).endOf('day').isAfter(moment())
    )
    const sortedSubEvents = sortBy(mappedSubEvents, (event) => event.start_time)
    const subEventsObject = {}
    sortedSubEvents.forEach((event, index) => {
      subEventsObject[index] = event
    })

    return {
      ...state,
      values: {
        ...state.values,
        sub_events: subEventsObject,
      },
    }
  }

  if (action.type === constants.EDITOR_ADD_OFFER) {
    let offersItems = []
    if (state.values.offers) {
      offersItems = JSON.parse(JSON.stringify(state.values.offers))
    }
    offersItems.push(action.values)

    return {
      ...state,
      values: {
        ...state.values,
        offers: offersItems,
      },
    }
  }

  if (action.type === constants.EDITOR_DELETE_OFFER) {
    const index = parseInt(action.offerKey, 10)
    const offers = JSON.parse(JSON.stringify(state.values.offers))
    offers.splice(index, 1)

    return {
      ...state,
      values: {
        ...state.values,
        offers,
      },
    }
  }

  if (action.type === constants.EDITOR_SET_FREE_OFFERS) {
    const offers = JSON.parse(JSON.stringify(state.values.offers))
    for (const offer of offers) {
      offer.is_free = action.isFree
    }

    const { offers: _removedOffers, ...rest } = state.values

    if (action.isFree === true) {
      // Event is free so we can clear the offers key from state store
      // this prevents validation errors on possibly already entered offer fields
      return {
        ...state,
        values: rest,
      }
    }

    return {
      ...state,
      values: {
        ...state.values,
        offers,
      },
    }
  }

  if (action.type === constants.EDITOR_SETLANGUAGES) {
    return {
      ...state,
      contentLanguages: action.languages,
    }
  }

  if (action.type === constants.VALIDATE_FOR) {
    return {
      ...state,
      validateFor: action.validateFor,
    }
  }

  if (action.type === constants.EDITOR_REPLACEDATA) {
    // Replace new values to existing values
    const newValues = {
      ...action.values,
    }

    if (state.mode === 'create')
      localStorage.setItem(editorValuesKey, JSON.stringify(newValues))

    return {
      ...state,
      values: newValues,
      contentLanguages: getContentLanguages(newValues),
    }
  }

  if (action.type === constants.EDITOR_CLEARDATA) {
    clearEventDataFromLocalStorage()

    return {
      ...state,
      values: editorValues,
      validationErrors: {},
      validateFor: null,
      validationStatus: constants.VALIDATION_STATUS.CLEARED,
    }
  }

  if (action.type === constants.EDITOR_SENDDATA_SUCCESS) {
    clearEventDataFromLocalStorage()

    return {
      ...state,
      values: editorValues,
    }
  }

  if (action.type === constants.EDITOR_RECEIVE_KEYWORDSETS) {
    return {
      ...state,
      keywordSets: action.keywordSets,
    }
  }

  if (action.type === constants.EDITOR_RECEIVE_LANGUAGES) {
    return {
      ...state,
      languages: action.languages,
    }
  }

  if (action.type === constants.RECEIVE_EVENT_FOR_EDITING) {
    return {
      ...state,
      values: {
        ...mapAPIDataToUIFormat({ ...action.event }, action.keywordSets),
        keywords: state.values.keywords,
        hobbyCategories: state.values.hobbyCategories,
      },
    }
  }

  if (action.type === constants.SELECT_IMAGE_BY_ID) {
    const newVal = get(action, 'img', null)
    // Merge new values to existing values
    const newValues = {
      ...state.values,
      image: newVal,
    }
    return {
      ...state,
      values: newValues,
    }
  }

  if (action.type === constants.SET_VALIDATION_ERRORS) {
    return {
      ...state,
      validationErrors: action.errors,
      validationStatus: constants.VALIDATION_STATUS.RESOLVE,
    }
  }

  if (action.type === constants.SET_SERVER_ERRORS) {
    return {
      ...state,
      serverErrorData: action.errors,
    }
  }

  return state
}
